import AOS from 'aos';
import 'aos/dist/aos.css';
import '../../styles/tailwind.less';
import './index.less';

const header = document.getElementById('header')!;
const menuButton = document.getElementById('mobile-menu-button')!;
const nav = document.getElementById('nav')!;
const cookie = document.getElementById('cookie')!;
const cookieClose = document.getElementById('cookie-close')!;
const bookingForm = document.getElementById('booking-form')!;

if (
  !document.cookie.split('; ').find((row) => row.startsWith('cookie_accepted'))
) {
  const expires = new Date(
    new Date().setFullYear(new Date().getFullYear() + 1),
  ).toUTCString();
  document.cookie = `cookie_accepted=true; expires=${expires}`;
  cookie.classList.remove('hidden');
}

menuButton.addEventListener('click', () => {
  nav.classList.toggle('mobile-menu-open');
});

cookieClose.addEventListener('click', () => {
  cookie.classList.add('hide-cookie');
});

let lastKnownScrollPosition = 0;
let ticking = false;

document.addEventListener('scroll', () => {
  lastKnownScrollPosition = window.scrollY;

  if (!ticking) {
    window.requestAnimationFrame(() => {
      if (nav.classList.contains('mobile-menu-open')) {
        nav.classList.remove('mobile-menu-open');
      }

      if (
        !header.classList.contains('sticky-menu') &&
        lastKnownScrollPosition > 0
      ) {
        header.classList.add('sticky-menu');
      } else if (lastKnownScrollPosition === 0) {
        header.classList.remove('sticky-menu');
      }
      ticking = false;
    });

    ticking = true;
  }
});

const formParent = bookingForm.parentElement!;
const formThank = document.getElementById('form-thank')!;

bookingForm.addEventListener('submit', async (e) => {
  e.preventDefault();

  const formData = new FormData(<HTMLFormElement>bookingForm);
  const name = formData.get('name');
  const email = formData.get('email');
  const phone = formData.get('phone');

  const xhr = new XMLHttpRequest();
  xhr.open('POST', '/api/prospect/');
  xhr.setRequestHeader('Content-Type', 'application/json');
  xhr.send(JSON.stringify({ name, email, phone }));

  xhr.addEventListener('load', () => {
    formThank.classList.remove('hidden');
    formParent.classList.add('hidden');
  });
});

AOS.init();
